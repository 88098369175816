.expandBtn {
  display: flex;
  align-items: baseline;
  width: 100px;
  justify-content: center;

  svg {
    margin-left: 10px;
  }
}

.main {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 25px;
  background-color: #fff;
  background-image: linear-gradient(rgba(88, 193, 233, 0.47), rgba(88, 193, 233, 0.47));
  background-size: 85% 1px;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.hamburger {
  height: 40px;
  width: 40px;
  padding: 7px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: var(--light-grey);
}

.navigationMenu {
  display: flex;
  margin-left: auto;
  padding: 0;
}

.navigationMenu li {
  list-style-type: none;
  margin: 0 16px;
}

.navItem {
  position: relative;
  margin-right: 50px;
}

.dropDownBtn {
  display: flex;
  align-items: center;
  font-size: var(--font-m);
  font-weight: var(--font-semibold);
  font-size: inherit;
  text-align: center;
  border: none;
  background-color: transparent;
  color: var(--font);
  cursor: pointer;
  width: 100%;
}

.dropDownBtn i {
  color: var(--light-cyan);
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: var(--font-regular);
  z-index: var(--z-topmost);
  min-width: 150px;
  top: 30px;
  list-style: none;
  border-radius: 8px;
  border: 2px solid white;
  display: none;
  background: white;

  &.open {
    display: block;
  }

  li {
    background: white;
  }
  
  li:not(:last-child) {
    border-bottom: 1px solid var(--light-grey-2);
  }
  
  li span {
    padding: 10px;
    width: 100%;
    display: inline-block;
    color: var(--font);
  }
  
  li span:hover {
    cursor: pointer;
    background-color: rgba(88, 193, 233, 0.47);
    border-radius: 4px;
  }
}

.userInfo {
  font-weight: bold;
  color: var(--font);
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .navigationMenu {
    display: none;
  }

  .navigationMenu {
    /* navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar) */
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    /* menu span full height and width */
    width: 100%;
    /* calc the height of the menu so it covers the whole page */
    height: calc(100vh - 77px);
    background-color: var(--white);
  }

  .navigationMenu li {
    text-align: center;
    margin: 0;
  }
  .navigationMenu li {
    color: var(--font);
    width: 100%;
    padding: 24px 0;
  }

  .navigationMenu li:hover {
    background-color: var(--light-cya);
  }

  .navigationMenu.expanded {
    display: block;
  }
}
