.logo{

}

.topMenu {
  display: flex;
  align-items: center;

  button {
    position: absolute;
    right: 20px;
  }
}